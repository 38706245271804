import React from "react"
import { graphql } from "gatsby"
import ReactHtmlParser from "react-html-parser"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"

// import PostDetails from "../sections/blog/PostDetails"
// import Comments from "../sections/blog/Comments"
// import Sidebar from "../sections/blog/Sidebar"

const BlogDetails = ({ data }) => {
  const post = data.contentfulBlogPost
  const title = post.title
  const slug = post.slug
  // const publishDate = get(post, "publishDate")
  // const image = get(post, "heroImage")
  // const author = get(post, "author.name")
  const og_image = post.heroImage.gatsbyImageData.images.fallback.src
  const description = post.description.description
  const body_html = post.body.childMarkdownRemark.html

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://geerd.ma/blog/${slug}`} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={og_image} />
      </Helmet>
      <Layout withPattern={false}>
        <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32 relative py-16 overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 text-primary-600 font-extrabold tracking-tight sm:text-4xl">
                  {title}
                </span>
              </h1>
              <p className="mt-8 text-xl text-gray-500 leading-8">
                {description}
              </p>
            </div>
            <div className="contentful-blog-post">
              {ReactHtmlParser(body_html)}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default BlogDetails
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      slug
      description {
        description
      }
      heroImage {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FULL_WIDTH
          resizingBehavior: SCALE
          formats: [AUTO, WEBP, AVIF]
        )
      }
      tags
      author {
        name
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
